.stanza-view
  scroll-margin-top: 120px
  > .titlelink-wrapper
    margin-left: -15px
    margin-bottom: 4px
    > .titlelink
      display: inline-block
      width: 11px
      font-family: "FontAwesome"
      text-decoration: none
      font-size: .7rem
      margin-right: 3px
      vertical-align: bottom
      color: var(--color-gray)
    &:hover
      .titlelink::before
        content: $CHAR_STANZA_LINK
    > .title
      display: inline-block
      margin: 0
      font-size: 16px
      line-height: 18px
      > span
        margin-left: 8px
        font-size: 12px
        &.mgend
          color: $COLOR_DATASET_DISEASE_MGEND
        &.clinvar
          color: $COLOR_DATASET_DISEASE_CLINVAR
  > .stanza
    background: white
    box-shadow: 0 2px 2px rgba(black, .2)
    margin-bottom: 20px
    overflow: scroll

    togostanza-gene-protein-structure
    --togostanza-iframe-max-width: ''
    &#gene-protein-browser
      overflow: visible
